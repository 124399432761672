import request from "@/request/http/index";
import { NB_PHONE_REG, NB_ACCOUNT_REG } from "@/utils/consts";
import appInfo from "@/utils/appInfo";
import myWindow from "@/utils/myWindow";
import { getDeviceId, getDeviceVersion } from "@/utils/util";
import { store } from "@/store";
import { requestPost, requestPostPromise } from "@/request/http/http";
import { NBOcType } from "@/utils/emun";
import customStore from "@/request/http/store";
import { useCustomRouter } from "@/router/custRouter";
import hallLogic from "./hallLogic";
import i18n from "@/locales";
import { showNbConfirmDialog } from "@/utils/dialog";
import cacheManager from "@/utils/cacheManager";
import { sendGoogleTag } from "@/scripts/tagEvent";

let googleAuthCallback: ((error: any) => void) | null = null;
let fbAuthCallback: ((error: any) => void) | null = null;

const loginLogic = {
  registerConfig: () => {
    return requestPostPromise(request.userApi.GetRegisterConfig, {
      siteType: 2,
    });
  },
  resetPassword: (
    countryCode: string,
    mobile: string,
    password: string,
    smsCode: string,
    callback: (arg0?: string) => void
  ) => {
    const params = {
      countryCode,
      method: "reset",
      mobile,
      smsCode,
      password,
      repeatPassword: password,
    };

    requestPost(
      request.userApi.ResetPassword,
      params,
      (_, message) => {
        callback(message);
      },
      null,
      null,
      true
    );
  },
  loginAccountRequest: async (
    username: string,
    password: string,
    checked: boolean,
    fundCheck: () => void,
    funds?: string,
    fundsKey?: string,
    captcha?: string,
    captchaType?: string,
    codeError?: () => void
  ) => {
    const params = {
      signInType: NB_ACCOUNT_REG,
      userName: username,
      password,
      terminal: appInfo.terminalType,
      deviceUniqueId: await getDeviceId(),
      loginDeviceSystem: getDeviceVersion(),
      funds,
      fundsKey,
      captcha: captcha,
      captchaType: captcha ? captchaType : undefined,
    };

    requestPost(
      request.userApi.Login,
      params,
      (response) => {
        if (checked) {
          cacheManager.increaseCache("user_login_account", {
            username,
            password,
          });
        } else {
          cacheManager.deleteCache("user_login_account");
        }

        handleLoginResult(response, "/login", !!funds && !!fundsKey);
        gtagLogin(response.userName);
      },
      (error) => {
        error && handleLoginError(error, fundCheck, codeError);
      },
      null,
      true
    );
  },
  loginPhoneRequest: async (
    countryCode: string,
    mobile: string,
    password: string,
    checked: boolean,
    fundCheck: () => void,
    funds?: string,
    fundsKey?: string,
    captcha?: string,
    captchaType?: string,
    codeError?: () => void
  ) => {
    const params = {
      signInType: NB_PHONE_REG,
      countryCode,
      mobile,
      password,
      terminal: appInfo.terminalType,
      deviceUniqueId: await getDeviceId(),
      loginDeviceSystem: getDeviceVersion(),
      funds,
      fundsKey,
      captcha: captcha,
      captchaType: captcha ? captchaType : undefined,
    };

    requestPost(
      request.userApi.Login,
      params,
      (response) => {
        if (checked) {
          cacheManager.increaseCache("user_login_phone", {
            mobile,
            countryCode,
            password,
          });
        } else {
          cacheManager.deleteCache("user_login_phone");
        }

        handleLoginResult(response, "/login", !!funds && !!fundsKey);
        gtagLogin(response.userName);
      },
      (error) => {
        error && handleLoginError(error, fundCheck, codeError);
      },
      null,
      true
    );
  },
  signUpWithAccount: async (
    userName: string,
    password: string,
    repeatPassword: string,
    countryCode: string,
    mobile: string,
    smsCode: string,
    invitationCode: string,
    realName: string,
    oAuthType?: string,
    oAuthEmail?: string,
    oAuthId?: string,
    captcha?: string,
    captchaType?: string,
    codeError?: () => void,
    agentError?: () => void
  ) => {
    const params = {
      regType: NB_ACCOUNT_REG,
      userName: userName && userName.length > 0 ? userName : undefined,
      password: password && password.length > 0 ? password : undefined,
      repeatPassword:
        repeatPassword && repeatPassword.length > 0
          ? repeatPassword
          : undefined,
      countryCode:
        mobile && mobile.length > 0 && countryCode && countryCode.length > 0
          ? countryCode
          : undefined,
      mobile: mobile && mobile.length > 0 ? mobile : undefined,
      smsCode:
        mobile && mobile.length > 0 && smsCode && smsCode.length > 0
          ? smsCode
          : undefined,
      invitationCode:
        invitationCode && invitationCode.length > 0
          ? invitationCode
          : undefined,
      realName: realName && realName.length > 0 ? realName : undefined,
      currency: store.getters.currencyId,
      terminal: appInfo.terminalType,
      deviceUniqueId: await getDeviceId(),
      oAuth: oAuthType ? 1 : undefined,
      oAuthEmail,
      oAuthType,
      oAuthId,
      captcha,
      captchaType: captcha ? captchaType : undefined,
      appsFlyerId: appInfo.appsFlyerId ?? undefined,
    };

    requestPost(
      request.userApi.SignUp,
      params,
      (response) => {
        handleLoginResult(response, "/register");
        gtagRegister(oAuthType);
      },
      (error) => {
        error && handleLoginError(error, undefined, codeError, agentError);
      },
      null,
      true
    );
  },
  signUpWithPhone: async (
    countryCode: string,
    mobile: string,
    smsCode: string,
    password: string,
    repeatPassword: string,
    invitationCode: string,
    realName: string,
    oAuthType?: string,
    oAuthEmail?: string,
    oAuthId?: string,
    captcha?: string,
    captchaType?: string,
    codeError?: () => void,
    agentError?: () => void
  ) => {
    const params = {
      regType: NB_PHONE_REG,
      userName: mobile && mobile.length > 0 ? mobile : undefined,
      countryCode:
        mobile && mobile.length > 0 && countryCode && countryCode.length > 0
          ? countryCode
          : undefined,
      mobile: mobile && mobile.length > 0 ? mobile : undefined,
      smsCode:
        mobile && mobile.length > 0 && smsCode && smsCode.length > 0
          ? smsCode
          : undefined,
      password: password && password.length > 0 ? password : undefined,
      repeatPassword:
        repeatPassword && repeatPassword.length > 0
          ? repeatPassword
          : undefined,
      invitationCode:
        invitationCode && invitationCode.length > 0
          ? invitationCode
          : undefined,
      realName: realName && realName.length > 0 ? realName : undefined,
      currency: store.getters.currencyId,
      terminal: appInfo.terminalType,
      deviceUniqueId: await getDeviceId(),
      oAuth: oAuthType ? 1 : undefined,
      oAuthEmail,
      oAuthType,
      oAuthId,
      captcha: captcha,
      captchaType: captcha ? captchaType : undefined,
      appsFlyerId: appInfo.appsFlyerId ?? undefined,
    };

    requestPost(
      request.userApi.SignUp,
      params,
      (response) => {
        handleLoginResult(response, "/register");
        gtagRegister(oAuthType);
      },
      (error) => {
        error && handleLoginError(error, undefined, codeError, agentError);
      },
      null,
      true
    );
  },
  signInGuest: () => {
    requestPost(
      request.userApi.NBGuestSignUp,
      null,
      (response) => {
        const guest = {
          // 保存时间、token、identity
          startTime: Date.now(),
          token: customStore.get("token"),
          identity: customStore.get("identity"),
        };
        cacheManager.increaseCache(cacheManager.CacheKey.DEMO_ACCOUNT, guest);
        handleLoginResult(response, "/guest");
      },
      null,
      null,
      true
    );
  },
  signInWithGoogle: (callback: (error: any) => void) => {
    googleAuthCallback = callback;
    myWindow.googleSignIn && myWindow.googleSignIn();
  },
  signInWithFacebook: (callback: (error: any) => void) => {
    fbAuthCallback = callback;
    myWindow.facebookSignIn && myWindow.facebookSignIn();
  },
  oauthLoginAccess: async (
    type: string,
    token: string,
    funds?: string,
    fundsKey?: string
  ) => {
    const oauth = store.state.oauthConfig.find((v) => v.ocType === type);
    const params = {
      deviceId: await getDeviceId(),
      terminal: appInfo.terminalType,
      type,
      id: oauth?.ocId,
      oauthId: oauth?.ocOauthId,
      accessValue: {
        code: token,
      },
      funds,
      fundsKey,
    };

    requestPost(
      request.userApi.OAuthLoginAccess,
      params,
      (response) => {
        handleLoginResult(response, "/thirdLogin", !!funds && !!fundsKey);
        gtagLogin(response.userName);
      }, //认证成功表示存在账号，直接登录了
      (error) => {
        if (error) {
          if (type === NBOcType.Google && googleAuthCallback != null) {
            googleAuthCallback(error);
          } else if (type === NBOcType.Facebook && fbAuthCallback != null) {
            fbAuthCallback(error);
          }
        }
      },
      null,
      true
    );
  },
  thirdSignOut: () => {
    if (myWindow.googleOnSignOut) myWindow.googleOnSignOut();
    if (myWindow.FBLogout) myWindow.FBLogout();
  },
  fundsCheck: (
    signInType: string,
    funds: string,
    otherArgs: any,
    callback: (funds: string, fundsKey: string) => void
  ) => {
    const params = {
      signInType,
      funds,
      ...otherArgs,
    };

    requestPost(
      request.userApi.FundsCheck,
      params,
      (response) => {
        response && callback(funds, response);
      },
      null,
      null,
      true
    );
  },
  captchaCheck: (type: string, callback: (shouldCheck: boolean) => void) => {
    requestPost(request.userApi.CaptchaCheck, { type }, (response) => {
      callback(response.status === "1");
    });
  },
};

const handleLoginResult = (
  response: any,
  action: string,
  changeDevice?: boolean
) => {
  if (response) {
    store.dispatch("user/updateUserInfo", response).then(() => {
      // 登录状态下进入时必须的请求
      hallLogic.getAllLoginRequest();
      const router = useCustomRouter(null);
      if (
        action == "/login" ||
        action == "/register" ||
        action == "/guest" ||
        action == "/thirdLogin"
      ) {
        router.replace("/").then(() => {
          if (changeDevice && (action == "/login" || action == "/thirdLogin")) {
            // 涉及资金密码登录，询问是否变更设备
            showNbConfirmDialog({
              message: i18n.global.t("dialog.CHANGE_DEVICE"),
              confirm: i18n.global.t("dialog.SETTING_BTN"),
            }).then(() => {
              router.push({ path: "/my/safety", query: { type: 3 } });
            });
          }
        });
      }
    });
  }
};

const handleLoginError = (
  error: any,
  fundCheck?: () => void,
  codeError?: () => void,
  agentError?: () => void
) => {
  if (error.errorcode === 300052) {
    // 非绑定装置，登入需要传资金密码
    fundCheck && fundCheck();
  } else if (error.errorcode === 101001) {
    codeError && codeError();
  } else if (error.errorcode === 105016) {
    // 推广码达到上限
    showNbConfirmDialog({
      message: i18n.global.t("loginConfig.agentCodeError"),
    })
      .then(agentError)
      .catch(() => {
        useCustomRouter(null).back();
      });
  }
};

const gtagRegister = (thirdType?: string) => {
  let method = "official";
  if (thirdType) {
    if (thirdType == NBOcType.Google) {
      method = "google";
    } else if (thirdType == NBOcType.Facebook) {
      method = "facebook";
    }
  }

  sendGoogleTag("sign_up", {
    method: method,
  });
};

const gtagLogin = (userName: string) => {
  sendGoogleTag("login", {
    user: userName,
  });
};

export default loginLogic;
