import myWindow from "@/utils/myWindow";

export const sendGoogleTag = (event: string, value: any) => {
  console.warn("tagEvent:: ", event, JSON.stringify(value));
  if (typeof myWindow.gtag === "function") {
    console.log("tagEvent::", myWindow.gtag);
    myWindow.gtag("event", event, value);
  } else {
    console.log("tagEvent:: gtag is not found");
  }
};
