import types from "@/store/mutation-types";
import { State } from "./state";

export default {
  [types.UPDATE_SITECONFIG](state: State, config: any) {
    state.siteConfig = config;
  },
  [types.UPDATE_USERTYPE](state: State, userType: string) {
    state.userType = userType;
  },
  [types.UPDATE_TOKEN](state: State, token: any) {
    state.token = token;
  },
  [types.UPDATE_OAUTH_CONFIG](state: State, config: any) {
    state.oauthConfig = config;
  },
  [types.UPDATE_CURRENCY_LIST](state: State, config: any) {
    state.currencyList = config;
  },
  [types.UPDATE_SMS_CONFIG](state: State, config: any) {
    state.smsConfig = config;
  },
  [types.UPDATE_BRAND_LOGO](state: State, config: any) {
    state.brandConfig = config;
  },
  [types.UPDATE_BRAND_ABOUT_US](state: State, config: any) {
    state.brandAboutUs = config;
  },
  [types.UPDATE_LANGUAGE_LIST](state: State, languageList: any) {
    state.languageList = languageList;
  },
  [types.UPDATE_BRAND_ACTIVATE](state: State, config: any) {
    state.brandActivate = config;
  },
  [types.UPDATE_CUSTOMER_SERVICE](state: State, config: any) {
    state.customerConfig = config;
  },
  [types.UPDATE_LOCALE_LANGUAGE](state: State, id: string) {
    state.localeLangId = id;
  },
  [types.UPDATE_TOP_BANNER_LIST](state: State, list: any) {
    state.bannerList = list;
  },
  [types.UPDATE_PWA_STATUS](state: State, show: boolean) {
    state.showPwa = show;
  },
};
