interface MyWindow extends Window {
  fbq: any; //fb记录事件的方法
  fbid: any;
  usfa: ((key: string) => string | null) | null; //urlSearchFindAt; 寻找url中对应参数的值
  agentCode: number | null; //代理码
  googleInit: any; // google sign in
  googleSignIn: any; // 触发google登录
  GoogleResponse: any; // google登录回调
  googleOnSignOut: any;
  fbAsyncInit: any; // fb sign in
  facebookSignIn: any; // 触发fb登录
  FBResponse: any; // fb登录回调
  FBLogout: any;
  gtag: any;
}

const myWindow = window as any as MyWindow;

export default myWindow;
