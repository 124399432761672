import { Module } from "vuex";
import { State } from "@/store/state";

export interface rebatesState {
  categoryList: any[]; //左侧导航
  userRebateInfos: any[]; //用户返利列表（所有类型）
  categoryActive:number;  //当前选中分类的索引
}

export const rebates: Module<rebatesState, State> = {
  namespaced: true,
  state: {
    categoryList: [],
    userRebateInfos: [],
    categoryActive:0
  },
  getters: {
    // 分类列表
    categoryList: (state) => state.categoryList,
    // 当前选中分类对应的返利列表
    UserRebateInfo: (state) => (index: number) => {
      const categoryId = state.categoryList[index]?.categoryId;
      return state.userRebateInfos.filter((v: any) => v.categoryId == categoryId)[0]?.list ?? []
    }
  },
  actions: {},
  mutations: {
    clearData(state) {
      state.userRebateInfos = [];
    },
    saveCategoryList(state, payload) {
      state.categoryList = payload.list;
    },
    saveUserRebateMap(state, payload) {
      const { categoryId, value } = payload;
      const i = state.userRebateInfos.findIndex(
        (v: any) => v.categoryId == categoryId
      );
      if (i == -1) {
        state.userRebateInfos = JSON.parse(
          JSON.stringify([
            ...state.userRebateInfos,
            {
              categoryId,
              list: value,
            },
          ])
        );
      } else {
        const newList = JSON.parse(JSON.stringify(state.userRebateInfos));
        newList[i].list = value;
        state.userRebateInfos = newList;
      }
    },
    updateCategoryActive(state,index){
      state.categoryActive = index
    }
  },
};
