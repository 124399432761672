import { IMG_DEF } from "@/constant/imgUrl";
import { CategoryItem, CategoryShowModel, SelectConfig } from "@/interface";

export const SELECT_VIEW_LIST_DEFAULT_TEXT = "FILTER_SELECT_ALL_TEXT";
export const time_screen = [
  { text: SELECT_VIEW_LIST_DEFAULT_TEXT },
  { text: "TIME_SELECT_TODAY_TEXT", type: 0 },
  { text: "TIME_SELECT_YESTERDAY_TEXT", type: 1 },
  { text: "TIME_SELECT_7_DAYS_TEXT", type: 6 },
  { text: "TIME_SELECT_15_DAYS_TEXT", type: 14 },
  { text: "TIME_SELECT_1_MONTH_TEXT", type: 29 },
];

export const DATE_FILTER_CONFIG: SelectConfig = {
  icon: require("assets/images/nb/ic-time.png"),
  title: "TIME_LABEL",
  selectedIndex: 2,
  defaultIndex: 2,
  list: [
    { text: "TIME_SELECT_TODAY_TEXT", type: 0 },
    { text: "TIME_SELECT_YESTERDAY_TEXT", type: 1 },
    { text: "TIME_SELECT_7_DAYS_TEXT", type: 6 },
    { text: "TIME_SELECT_15_DAYS_TEXT", type: 14 },
    { text: "TIME_SELECT_1_MONTH_TEXT", type: 29 },
  ],
};

export const DATE_FILTER_CONFIG_FOR_WITHDRAW_RECORD: SelectConfig = {
  icon: require("assets/images/nb/ic-time.png"),
  title: "TIME_LABEL",
  selectedIndex: 0,
  defaultIndex: 0,
  list: [
    { text: SELECT_VIEW_LIST_DEFAULT_TEXT },
    { text: "TIME_SELECT_7_DAYS_TEXT", type: 7 },
    { text: "TIME_SELECT_1_MONTH_TEXT", type: 30 },
  ],
};

export const STATUS_FILTER_CONFIG_FOR_WITHDRAW_RECORD: SelectConfig = {
  icon: require("assets/images/nb/ic-type.png"),
  title: "TYPE_LABEL",
  selectedIndex: 0,
  defaultIndex: 0,
  list: [
    { text: SELECT_VIEW_LIST_DEFAULT_TEXT },
    { text: "withdrawal.RECORD_STATUS.1", type: 1 },
    { text: "withdrawal.RECORD_STATUS.2", type: 2 },
    { text: "withdrawal.RECORD_STATUS.6", type: 6 },
    { text: "withdrawal.RECORD_STATUS.3", type: 3 },
    { text: "withdrawal.RECORD_STATUS.4", type: 4 },
    { text: "withdrawal.RECORD_STATUS.5", type: 5 },
  ],
};

export const DATE_FILTER_CONFIG_FOR_RECORD_RECORD: SelectConfig = {
  icon: require("assets/images/nb/ic-time.png"),
  title: "TIME_LABEL",
  selectedIndex: 0,
  defaultIndex: 0,
  list: [
    { text: SELECT_VIEW_LIST_DEFAULT_TEXT },
    { text: "TIME_SELECT_TODAY_TEXT", type: 0 },
    { text: "TIME_SELECT_1_MONTH_TEXT", type: 30 },
  ],
};

export const STATUS_FILTER_CONFIG_FOR_RECORD_RECORD: SelectConfig = {
  icon: require("assets/images/nb/ic-type.png"),
  title: "TYPE_LABEL",
  selectedIndex: 0,
  defaultIndex: 0,
  list: [
    { text: SELECT_VIEW_LIST_DEFAULT_TEXT },
    { text: "shop.RECORD_STATUS.0", type: 0 },
    { text: "shop.RECORD_STATUS.2", type: 2 },
    { text: "shop.RECORD_STATUS.1", type: 1 },
    { text: "shop.RECORD_STATUS.3", type: 3 },
  ],
};

export const CATEGORY_TYPE = {
  TOTAL: -99, //全部
  BOARD: 1, //棋牌
  FISHING: 2, //捕鱼
  SLOT: 3, //电子
  LOTTERY: 4, //彩票
  SPORTS: 5, //体育
  LIVE: 6, //真人
  ESPORTS: 7, //电竞
  HOT: 10, //热门
};

export const PLATFORM_TYPE = {
  OTHER: -99,
  FG: 1,
  MP: 2,
  CQ9: 10,
  JDB: 11,
  TY: 15,
  BSP: 17,
  SSP: 21,
  AG: 22,
  DSG: 23,
  EVO: 24,
  PP: 25,
  PG: 26,
};

export const CATEGORY_SHOW_TYPE = {
  TOTAL: -99, //全部
  COLLECTION: 99, //收藏
};

export const CATEGORY_HALL_TOTAL: CategoryShowModel = {
  category_id: CATEGORY_SHOW_TYPE.TOTAL,
  name: "GAME_CATEGORY_TOTAL_TEXT",
  icon: IMG_DEF("other/fz3.png"),
  data: [],
};
export const CATEGORY_HALL_COLLECTION: CategoryShowModel = {
  category_id: CATEGORY_SHOW_TYPE.COLLECTION,
  name: "GAME_CATEGORY_COLLECTION_TEXT",
  icon: IMG_DEF("other/favoritos1.png"),
  data: [],
};
export const CATEGORY_CONFIG: CategoryItem[] = [
  {
    id: CATEGORY_TYPE.HOT,
    button: "Hot_Button",
    desc: "GAME_CATEGORY_HOT_TEXT",
    show: false,
    icon: "/Quente3.png",
    activeIcon: "/Quente-active.png",
    pcWidth: 2.64,
    h5Width: 2.93,
    margin: 0.8,
  },
  {
    id: CATEGORY_TYPE.BOARD,
    button: "Board_Button",
    desc: "GAME_CATEGORY_BOARD_TEXT",
    show: false,
    icon: "/poker3.png",
    activeIcon: "/poker-active.png",
    pcWidth: 2.64,
    h5Width: 2.93,
    margin: 0.8,
  },
  {
    id: CATEGORY_TYPE.ESPORTS,
    button: "Esports_Button",
    desc: "GAME_CATEGORY_ESPORTS_TEXT",
    show: false,
    icon: "/e-sports.png",
    activeIcon: "/e-sports-selected.png",
    pcWidth: 2.64,
    h5Width: 2.93,
    margin: 0.8,
  },
  {
    id: CATEGORY_TYPE.FISHING,
    button: "Fishing_Button",
    desc: "GAME_CATEGORY_FISHING_TEXT",
    show: false,
    icon: "/Pescaria3.png",
    activeIcon: "/Pescaria-active.png",
    pcWidth: 2.84,
    h5Width: 3.43,
    margin: 0.8,
  },
  {
    id: CATEGORY_TYPE.LIVE,
    button: "Live_Button",
    desc: "GAME_CATEGORY_LIVE_TEXT",
    show: false,
    icon: "/sz3.png",
    activeIcon: "/sz-active.png",
    pcWidth: 2.64,
    h5Width: 2.93,
    margin: 0.8,
  },
  {
    id: CATEGORY_TYPE.LOTTERY,
    button: "Lottery_Button",
    desc: "GAME_CATEGORY_LOTTERY_TEXT",
    show: false,
    icon: "/lottery.png",
    activeIcon: "/lottery-selected.png",
    pcWidth: 2.64,
    h5Width: 2.93,
    margin: 0.8,
  },
  {
    id: CATEGORY_TYPE.SLOT,
    button: "Slot_Button",
    desc: "GAME_CATEGORY_SLOT_TEXT",
    show: false,
    icon: "/yk3.png",
    activeIcon: "/yk-active.png",
    pcWidth: 2.64,
    h5Width: 2.93,
    margin: 0.8,
  },
  {
    id: CATEGORY_TYPE.SPORTS,
    button: "Sports_Button",
    desc: "GAME_CATEGORY_SPORTS_TEXT",
    show: false,
    icon: "/sports.png",
    activeIcon: "/sports-selected.png",
    pcWidth: 2.64,
    h5Width: 2.93,
    margin: 0.8,
  },
];

export const NB_ACCOUNT_REG = "1";
export const NB_PHONE_REG = "2";
export const PORTRAIT = "portrait"; //竖屏

export const AccountRegex = /^[0-9a-zA-Z_]{6,16}$/; // 账号校验正则
export const PasswordRegex = /^(?=.*[a-zA-Z])(?=.*\d)[0-9a-zA-Z!@()*_-]{6,16}$/;
export const RealnameRegexStr = "^[\\u4e00-\\u9fa5a-zA-Z·]{2,50}$";
export const RealnameFormatter = /[^\u4e00-\u9fa5a-zA-Z·]/g;
export const InviteCodeRegexStr = "^[0-9a-zA-Z]{6}$";
export const InviteCodeFormatter = /[^0-9a-zA-Z]/g;

export const PIN_REG_STR = "^\\d{6}$";
export const PIN_FORNATTER = /[^\d]/g;

export const CPFRemarkRegexStr = "^.{1,10}$";
export const PixRegStr = "^\\d{3}.\\d{3}.\\d{3}-\\d{2}$";
export const removeExceptDigit = /[^\d]/g;

export const DeviceRegexStr = "^.{1,12}$";

export const DATE_FORMAT = {
  //日期格式
  yymmdd_hhmmss: "dd/MM/yy hh:mm:ss",
  yymmdd_hhmm: "dd/MM/yy hh:mm",
  yymmdd: "dd/MM/yy",
  mmdd_hhmm: "dd/MM hh:mm",
};

export const CATEGORY_BTN_TYPE = {
  total: 0,
  slot: 1,
  lottery: 2,
  live: 3,
  sport: 4,
  cards: 5,
  fishing: 6,
  popular: 7,
  favorites: 8,
};

export const CATEGORY_BTN_LIST = [
  {
    categoryId: CATEGORY_BTN_TYPE.slot,
    name: "categorybtn.SLOT",
    icon: require("@/assets/images/nb/category/slot.svg"),
    iconActive: require("@/assets/images/nb/category/slot-active.svg"),
  },
  {
    categoryId: CATEGORY_BTN_TYPE.lottery,
    name: "categorybtn.LOTTERY",
    icon: require("@/assets/images/nb/category/lottery.svg"),
    iconActive: require("@/assets/images/nb/category/lottery-active.svg"),
  },
  {
    categoryId: CATEGORY_BTN_TYPE.live,
    name: "categorybtn.LIVE",
    icon: require("@/assets/images/nb/category/live.svg"),
    iconActive: require("@/assets/images/nb/category/live-active.svg"),
  },
  {
    categoryId: CATEGORY_BTN_TYPE.sport,
    name: "categorybtn.SPORT",
    icon: require("@/assets/images/nb/category/sport.svg"),
    iconActive: require("@/assets/images/nb/category/sport-active.svg"),
  },
  {
    categoryId: CATEGORY_BTN_TYPE.cards,
    name: "categorybtn.CARDS",
    icon: require("@/assets/images/nb/category/cards.svg"),
    iconActive: require("@/assets/images/nb/category/cards-active.svg"),
  },
  {
    categoryId: CATEGORY_BTN_TYPE.fishing,
    name: "categorybtn.FISHING",
    icon: require("@/assets/images/nb/category/fishing.svg"),
    iconActive: require("@/assets/images/nb/category/fishing-active.svg"),
  },
  {
    categoryId: CATEGORY_BTN_TYPE.popular,
    name: "categorybtn.POPULAR",
    icon: require("@/assets/images/nb/category/popular.svg"),
    iconActive: require("@/assets/images/nb/category/popular-active.svg"),
  },
  {
    categoryId: CATEGORY_BTN_TYPE.favorites,
    name: "categorybtn.FAVORITES",
    icon: require("@/assets/images/nb/category/favorite.svg"),
    iconActive: require("@/assets/images/nb/category/favorite-active.svg"),
  },
];

export const OcTypeName = {
  "1": "Google",
  "2": "Facebook",
};
