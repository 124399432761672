import { createI18n } from "vue-i18n";
// import vantPtBR from "vant/es/locale/lang/pt-BR";
// import vantEnUS from "vant/es/locale/lang/en-US";
// import vantZhCN from "vant/es/locale/lang/zh-CN";
// import vantEsES from "vant/es/locale/lang/es-ES";
// import vantViVN from "vant/es/locale/lang/vi-VN";
// import { Locale } from "vant";
// //vant的国际化
// const vantMessages = {
//   "pt-BR": vantPtBR,
//   "en-US": vantEnUS,
//   "zh-CN": vantZhCN,
//   "vi-VN": vantViVN,
//   "es-ES": vantEsES,
// };

const i18n = createI18n({
  legacy: false,
  locale: "en",
  // fallbackLocale: "en-US",
  warnHtmlMessage: false,
});

export async function setI18nLanguage(locale: string) {
  i18n.global.locale.value = locale;
  document.querySelector("html")?.setAttribute("lang", locale);

  // const vantMsg = await import(
  // /* webpackChunkName: "vant-locale-[request]" */ `../../node_modules/vant/es/locale/lang/${locale}.d.ts`
  // );
  // Locale.use(locale, vantMsg);
  // if ((vantMessages as any)[locale]) {
  //   Locale.use(locale, (vantMessages as any)[locale]);
  // } else {
  //   Locale.use("en-US", vantEnUS);
  // }
}

import { nextTick } from "vue";
export async function loadLocaleMessages(locale: string) {
  if (i18n.global.availableLocales.includes(locale)) {
    setI18nLanguage(locale);
    return nextTick();
  }

  const msgDefault = await import(
    /* webpackChunkName: "locale-[request]" */ `../locales/${locale}.json`
  );

  i18n.global.setLocaleMessage(locale, {
    ...msgDefault.default,
  });

  setI18nLanguage(locale);
  return nextTick();
}

export default i18n;
