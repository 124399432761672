import { UserVipConfigApi } from "@/request/http/index";
import { store } from "@/store";
import { requestPost } from "@/request/http/http";

const vipLogic = {
  getVipConfig: () => {
    requestPost(UserVipConfigApi.GetList, null, (response) => {
      const config = response as any | null;
      if (config == null) {
        return;
      }

      store.commit("vips/saveVipInfo", { config });
    });
  },

  getUserVipInfo: () => {
    requestPost(UserVipConfigApi.GetUserVipInfo, null, (response) => {
      const info = response as any | null;
      if (info == null) {
        return;
      }
      store.commit("vips/saveLevelInfo", { info });
    });
  },
  getBonusList: () => {
    requestPost(UserVipConfigApi.GetBonusList, null, (response) => {
      const info = response as any | null;
      if (info == null) {
        return;
      }
      store.commit("vips/saveBonusList", { info });
    });
  },
  getPrize: (id: any, prize_type: string, callback: (res: any) => void) => {
    const params = {
      id,
      type: prize_type,
    };
    requestPost(
      UserVipConfigApi.GetReward,
      params,
      (response) => {
        if (response == null) return;
        vipLogic.getUserVipInfo();
        callback(response);
      },
      null,
      null,
      true
    );
  },
};

export default vipLogic;
