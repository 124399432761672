import { Module } from "vuex";
import { State } from "@/store/state";
import cacheManager from "@/utils/cacheManager";

interface VipState {
  vipConfig: any | null;
  levelInfo: any | null;
  bonusList: any | null;
}

export const vips: Module<VipState, State> = {
  namespaced: true,
  state: {
    vipConfig: cacheManager.queryCache(cacheManager.CacheKey.VIP_CONFIG),
    levelInfo: null,
    bonusList: null,
  },
  getters: {
    vipLevel(state) {
      if (state.levelInfo == null) {
        return {
          level: "1",
          betRadio: 0,
          rechargeRadio: 0,
          betProgress: "0/0",
          rechargeProgress: "0/0",
          nextLevel: null,
          currentCashBack: null,
          nextCashBack: null,
          betShow: false,
          rechargeShow: false,
        };
      }

      const { info } = state.levelInfo;

      const nextBetAmount = Number(info.nextBetAmount ?? "0");
      const nextRechargeAmount = Number(info.nextRechargeAmount ?? "0");
      const nowBetAmountLevelUp = Number(info.nowBetAmountLevelUp ?? "0");
      const nowRechargeAmountLevelUp = Number(
        info.nowRechargeAmountLevelUp ?? "0"
      );

      const betRadio =
        nextBetAmount > 0
          ? (nowBetAmountLevelUp * 1.0) / nextBetAmount > 1
            ? 100
            : ((nowBetAmountLevelUp * 1.0) / nextBetAmount) * 100
          : 0;
      const rechargeRadio =
        nextRechargeAmount > 0
          ? (nowRechargeAmountLevelUp * 1.0) / nextRechargeAmount > 1
            ? 100
            : ((nowRechargeAmountLevelUp * 1.0) / nextRechargeAmount) * 100
          : 0;
      const betProgress = nowBetAmountLevelUp + "/" + nextBetAmount;
      const rechargeProgress =
        nowRechargeAmountLevelUp + "/" + nextRechargeAmount;

      return {
        level: info.uvcId ?? "1",
        betRadio,
        rechargeRadio,
        betProgress,
        rechargeProgress,
        nextLevel: info.nextUvcId,
        currentCashBack: info.currentCashBack,
        nextCashBack: info.nextCashBack,
        betShow: info.betLevelUp === "1",
        rechargeShow: info.rechargeLevelUp === "1",
      };
    },
    vipDataList(state) {
      return state.vipConfig ?? [];
    },
    vipBonusConfig(state) {
      const { daySwitch, day, weekSwitch, week, monthSwitch, month, grade } =
        state.levelInfo;
      return {
        showDay: daySwitch == "1" ? true : false,
        showWeek: weekSwitch == "1" ? true : false,
        showMonth: monthSwitch == "1" ? true : false,
        day,
        week,
        month,
        grade,
      };
    },
    vipBonusList(state) {
      return state.bonusList;
    },
    vipLevelImage: () => (level: string) => {
      return require("@/assets/images/nb/vip/img_vip_0" +
        (Number(level ?? "1") > 10 ? 10 : level) +
        ".png");
    },
  },
  actions: {},
  mutations: {
    saveVipInfo(state, payload) {
      state.vipConfig = payload.config;
      cacheManager.increaseCache(
        cacheManager.CacheKey.VIP_CONFIG,
        payload.config
      );
    },
    saveLevelInfo(state, payload) {
      state.levelInfo = payload.info;
      // cacheManager.increaseCache(cacheManager.CacheKey.VIP_CONFIG, payload.config)
    },
    saveBonusList(state, payload) {
      state.bonusList = payload.info;
    },
    clearData(state) {
      state.vipConfig = null;
      cacheManager.deleteCache(cacheManager.CacheKey.VIP_CONFIG);
    },
  },
};
