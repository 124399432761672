import platform from "@/platform"
/**
 * 
 * @param name 图片路径
 * @param isLang 图片的文字需要国际化
 * @param isDiff 不同台子有区别的图片
 * @returns 
 */
export const IMG_DEF = (name: string, isLang?: boolean, isDiff?: boolean) => {
  let path = process.env.VUE_APP_IMG_PREFIX_DEFAULT;
  path += isDiff ? platform.diffPath : 'default/';
  if (isLang) path += 'htext/' + platform.lang + '/';
  return path + name;
}

export const IMG_IS_LANG = (name: string) => {
  return IMG_DEF(name, true, false);
}

export const IMG_DIFF = (name: string) => {
  return IMG_DEF(name, false, true);
}

export const IMG_DIFF_AND_LANG = (name: string) => {
  return IMG_DEF(name, true, true);
}
