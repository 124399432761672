export default {
  showLoading: true,
  hasNetwork: true,
  menuOpened: false,
  serviceShow: false,
  "user.nbUserInfo": null,
  "user.balanceList": [],
  smsConfig: [],
  oauthConfig: [],
  showSpinSidebar: false,
  showDownloadBar: false,
  showPwa: false,
  chromiumReadyPwa: false,
  customerConfig: null,
  "agent.invitationCode": null,
  "agent.rebateStatistics": null,
  "agent.invitationApps": null,
  "withdraw.withdrawRecord": null,
  "withdraw.withdrawRecordConfig": null,
  "recharge.rechargeRecord": null,
  "recharge.rechargeRecordConfig": null,
  "redpack.showFloating": true,
  "redpack.redpackList": null,
  "redpack.redpackDocs": null,
  "redpack.showRedpackPopup": false,
  "redpack.popupData": null,
  "messages.messages": 0,
  "messages.notice": 0,
  "messages.msgList": null,
  "messages.msgListConfig": null,
  "messagePopupBox.popupData": null,
  "messagePopupBox.messageQueue": [],
  "order.sportOrderList": null,
};
