import i18n from "@/locales";
import { showConfirmDialog, showDialog } from "vant";

interface NbDialogOptions {
  title?: string;
  message: string;
  cancel?: string;
  confirm?: string;
  allowHtml?: boolean;
  noOverly?: boolean;
}

export const showNbConfirmDialog = ({
  title,
  message,
  cancel,
  confirm,
  allowHtml,
  noOverly,
}: NbDialogOptions) => {
  return showConfirmDialog({
    title: title ?? i18n.global.t("dialog.WARNING_TITLE"),
    message,
    className: "as-dialog-default",
    cancelButtonText: cancel ?? i18n.global.t("dialog.CANCEL_BTN"),
    confirmButtonText: confirm ?? i18n.global.t("dialog.CONFIRM_BTN"),
    allowHtml,
    overlayClass: noOverly ? "nb-back-game-no-overlay" : undefined,
  });
};

export const showNbDialog = ({ title, message, confirm }: NbDialogOptions) => {
  return showDialog({
    title: title ?? i18n.global.t("dialog.WARNING_TITLE"),
    message,
    className: "as-dialog-default",
    confirmButtonText: confirm ?? i18n.global.t("dialog.CONFIRM_BTN"),
    teleport: "#app",
  });
};
