export enum HasDemoEnum {
  hasDemo = 1,
  NoDemo = 2,
}

export enum NBOcType {
  Google = "1",
  Facebook = "2",
}

export enum NBWithdrawType {
  BANK = 1,
  PIX = 2,
  USDT = 3,
  CG = 5,
}

export enum NBCaptchaType {
  LETTER_DIGIT = "1",
  SLIDER = "2",
}
