import myWindow from "@/utils/myWindow";

export enum WebDeviceType {
  NotWeb = 0,
  PC = 1,
  ANDROID = 2,
  IOS = 3,
}

export enum WebBrowserType {
  Unknown = 0,
  Chrome = 1,
  Edge = 2,
  Firefox = 3,
  Opera = 4,
  Safari = 5,
  Samsung = 6,
}

let webDeviceType = WebDeviceType.NotWeb as WebDeviceType;
let webBrowserType = WebBrowserType.Unknown as WebBrowserType;

const getWebType = () => {
  console.log(navigator.userAgent);
  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    webDeviceType = WebDeviceType.IOS;
  } else if (/Android/i.test(navigator.userAgent)) {
    webDeviceType = WebDeviceType.ANDROID;
  } else {
    webDeviceType = WebDeviceType.PC;
  }

  if (/Chrome/i.test(navigator.userAgent)) {
    webBrowserType = WebBrowserType.Chrome;
  } else if (/Edge/i.test(navigator.userAgent)) {
    webBrowserType = WebBrowserType.Edge;
  } else if (/Firefox/i.test(navigator.userAgent)) {
    webBrowserType = WebBrowserType.Firefox;
  } else if (/Opera/i.test(navigator.userAgent)) {
    webBrowserType = WebBrowserType.Opera;
  } else if (/Safari/i.test(navigator.userAgent)) {
    webBrowserType = WebBrowserType.Safari;
  } else if (/Samsung/i.test(navigator.userAgent)) {
    webBrowserType = WebBrowserType.Samsung;
  }
};

getWebType();

const terminalType = "h5";
console.log("terminalType::", terminalType);

interface AppInfoConfig {
  terminalType: string;
  deviceId: string | null;
  webDeviceType: WebDeviceType;
  webBrowserType: WebBrowserType;
  agentCode: string | null;
  channelCode: string | null;
  appsFlyerId: string | null;
}

const appInfo: AppInfoConfig = {
  terminalType,
  deviceId: null,
  webDeviceType,
  webBrowserType,
  agentCode: null,
  channelCode: null,
  appsFlyerId: null,
};

const signupParams = localStorage.getItem("signupParams");
if (signupParams && signupParams.length > 0) {
  // 落地页带上的渠道和代理码
  try {
    const temp = JSON.parse(signupParams);
    appInfo.agentCode = temp.agentCode;
    appInfo.channelCode = temp.channelCode;
  } catch {
    //
  }
}

// 除了落地页，如果链接上也有，那么以链接上为主
const agentCode =
  typeof myWindow.usfa == "function" ? myWindow.usfa("agentCode") : null;
if (agentCode) {
  appInfo.agentCode = agentCode;
  localStorage.setItem("agentCode", agentCode);
} else {
  if (!appInfo.agentCode) {
    // 落地页拿不到才从这里拿
    appInfo.agentCode = localStorage.getItem("agentCode");
  }
}

const channelCode =
  typeof myWindow.usfa == "function" ? myWindow.usfa("channelCode") : null;
if (channelCode) {
  appInfo.channelCode = channelCode;
  localStorage.setItem("channelCode", channelCode);
} else {
  if (!appInfo.channelCode) {
    // 落地页拿不到才从这里拿
    appInfo.channelCode = localStorage.getItem("channelCode");
  }
}

const appsFlyerId =
  typeof myWindow.usfa == "function" ? myWindow.usfa("appsFlyerId") : null;
appInfo.appsFlyerId = appsFlyerId;

export default appInfo;
