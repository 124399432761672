import { Module } from "vuex";
import { State } from "@/store/state";
import { CategoryShowModel } from "@/interface";
import cacheManager from "@/utils/cacheManager";
import {
  CATEGORY_CONFIG,
  CATEGORY_HALL_COLLECTION,
  CATEGORY_BTN_TYPE,
} from "@/utils/consts";
import i18n from "@/locales";

export interface GamesState {
  btnList: any[];
  categoryId: number;
  platformId: string | null;
  platformList: any[];
}

export const games: Module<GamesState, State> = {
  namespaced: true,
  state: {
    btnList: [],
    categoryId: CATEGORY_BTN_TYPE.total,
    platformId: null,
    platformList: [],
  },
  getters: {
    gameShowCategoryName: (state) => (id: number) => {
      const showCategory = state.btnList.filter((v) => v.category_id == id)[0];
      return showCategory ? showCategory.name : "";
    },
    categoryDesc: () => (category_id: number) => {
      return CATEGORY_CONFIG.filter((v) => v.id == category_id)[0].desc;
    },
    gameOptions(state, getters) {
      const lists = Array<any>();
      //加入收藏的标签
      lists.push({
        value: CATEGORY_HALL_COLLECTION.category_id,
        label: i18n.global.t(CATEGORY_HALL_COLLECTION.name),
        children: getters.gameCollectedList.map((m: any) => {
          return { value: m.game_id, label: m.game_name };
        }),
      });
      //加入接口返回的标签
      state.btnList.forEach((v: CategoryShowModel) => {
        lists.push({
          value: v.category_id,
          label: v.name,
          children: v.data.map((m) => {
            return { value: m.game_id, label: m.game_name };
          }),
        });
      });

      return lists;
    },
    platformList: (state) => (id: string | null) => {
      if (id) return state.platformList.filter((v: any) => v.id == id);
      else return state.platformList;
    },
  },
  actions: {
    saveBtnList({ commit }, payload) {
      setTimeout(() => {
        cacheManager.increaseCache(
          cacheManager.CacheKey.CATE_BTN_LIST,
          payload.btnList
        );
      });
      commit("saveBtnList", payload);
    },
  },
  mutations: {
    saveBtnList(state, payload) {
      state.btnList = payload.btnList;
    },
    // setH5StartPopupShow(state, payload) {
    //   state.h5StartPopupShow = payload?.show
    //   state.h5SelectedGameId = payload?.gameId ?? null
    // },
    changeGameCollect(state, payload) {
      const gameId = payload?.gameId;
      if (!gameId) return;
      const newBtnList = state.btnList;
      newBtnList[0].list.forEach((value: any) => {
        if (value.id == gameId) value.isFavorite = "0";
      });
      state.btnList = newBtnList;
    },
    changeCategoryId(state, payload) {
      state.categoryId = payload.categoryId;
    },
    changePlatformId(state, payload) {
      state.platformId = payload.platformId;
    },
    savePlatFormList(state, payload) {
      state.platformList = payload.list;
    },
  },
};
