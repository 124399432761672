// import config from './brazil-asjogo.json';
import config from './brazil-aswin.json';
// import config from './india-ascassino.json';

/**
 * 控制提现类型和银行卡添加页面
 * withdrawType: 1(india bank) 2(india upi) 3(india paytm) 4(barzil pix)。
 * diffPath: 个性化的图片路径
 * bank: 页面银行信息描述
 */


export default config